@import 'breakpoints';

.loadingWrapper {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);

    > p {
        font-size: 1.2em;
    }
}

.completeWrapper {
    max-width: 800px;
    margin-inline: auto;
    padding: 0 15px;

    h2 {
        margin-top: 0;
        margin-bottom: 15px;
    }
    
    p {
        margin: 0;
    }
}

.noticeWaiting {
    background-color: #efefef;
    border-radius: 10px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.noticeReset {
    background-color: #FFCCCB;
    border-radius: 10px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

.noticeSuccess {
    background-color: #EDFFEC;
    border-radius: 10px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.addresses {
    display: grid;
    grid-template-columns: 1fr;  
    grid-gap: 20px 20px;  

    @include sm {
        grid-template-columns: 1fr 1fr;    
    }
}

.address {
    span {
        display: block;
    }
}

.cart {

}

.cartHeader {
    display: grid;
    grid-template-columns: 50px 2fr;
    align-items: center;  
    margin-bottom: 20px;
    grid-row-gap: 10px;

    @include sm {
        grid-template-columns: 50px 2fr 1fr;
        margin-bottom: 10px;
    }

    .cartLogo {
        figure {
            width: 40px;
            height: 40px;
            margin: 0;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
                object-fit: contain;
                aspect-ratio: 1/1;
                object-position: center center;    
                width: 100%;
                height: auto;
                border-radius: 50%;
                border: 1px solid #000000;
                padding: 1px;
                color: transparent;
            }
        }    
    }

    .cartTitle {

    }

    .cartStatus {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: left;

        @include sm {
            grid-column: initial;
            text-align: right;
        }

        > span {
            background-color: #efefef;
            font-size: 0.8em;
            padding: 5px 10px;
            border-radius: 6px;
            text-transform: uppercase;
        }
    }
}

.cartLines {
    border: 1px solid #efefef;
    padding: 20px 15px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.cartLine {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-areas: "image title"
        "cost cost";
    grid-column-gap: 20px;
    align-items: center;  
    width: 100%;
    margin-bottom: 20px;

    @include sm {
        grid-template-columns: 50px 1fr 150px;
        grid-template-areas: "image title cost";
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.cartLineCost {
    grid-area: cost;
    text-align: right;
    font-size: 1.1em;
}

.cartLineImage {
    grid-area: image;
    text-align: center;

    figure {
        margin: 0;
        max-width: 100%;
        height: auto;

        img {
            object-fit: contain;
            aspect-ratio: 1/1;
            object-position: center center;
            width: 100%;
            height: auto; 
            color: transparent;
        }
    }
}

.cartLineTitle {
    grid-area: title;
    font-weight: 500;
    font-size: 1.1em;
}

.cartLineSku {
    font-weight: normal;
    font-size: 0.9em;
    font-style: italic;
    color: #666666;
    display: block;
}

.cartTotals {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    font-size: 1.1em;
}

.cartTotalsTitle {
    font-weight: 500;
}

.cartTotalsTax {
    display: block;
    font-size: 0.9em;
    font-style: italic;
    color: #666666;
}

.cartTotalsCost {
    text-align: right;
}

.customerCreatedAt {
    text-align: left;

    @include sm {
        text-align: right;
    }

    > span {
        display: inline;
        font-size: 1.2em;
        font-weight: normal;
        background-color: #efefef;
        border-radius: 10px;
        padding: 5px 10px;
    }
}

.orderHeader {
    margin-bottom: 15px;

    @include sm {
        display: grid;
        grid-template-columns: 1fr 200px;
        align-items: center;
    }
}

.externalOrderId {
    margin-bottom: 20px;

    @include sm {
        margin-bottom: 10px;
    }
}