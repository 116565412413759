@import 'breakpoints';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h2 {
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 5px;
}

p {
  line-height: 1.4;
}

b {
  font-weight: 500;
}

textarea, input[type="text"], input[type="email"], input[type="number"], input[type="submit"] {
  -webkit-appearance: none;
}

.spacer5 {
  height: 5px;
}

.spacer15 {
  height: 15px;
}

.spacer30 {
  height: 30px;
}

.checkoutStepsWrapper {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;

  display: grid;

  @include lg {
      grid-template-columns: 1.25fr 1fr;
      padding-top: 15px;
  }
}

.checkoutSteps {
  padding: 0;
  margin: 15px;
  order: 2;

  @include sm {
      padding: 0 50px;
  }

  @include md {
      padding: 0 100px;
  }

  @include lg {
      padding: 0;
      order: 1;
      margin-top: 0;
  }

}

.fullWidthWrapper {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;

  @include lg {
    padding-top: 15px;
  }
}

hr {
  border: 0;
  height: 1px;
  background-color: #efefef;
  margin: 20px 0;
}