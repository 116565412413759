@import 'breakpoints';

.header {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.profile {
    flex: 1;
    display: flex;
    align-items: center;
}

.profileImage {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        object-fit: cover;
        aspect-ratio: 1/1;
        object-position: center center;    
        width: 100%;
        height: auto;
        border-radius: 50%;
        border: 1px solid #000000;
        padding: 1px; 
        color: transparent;
    }
}

.profileHandle {
    font-weight: bold;
    font-size: 0.9em;
}

.help {
    cursor: pointer;
    font-size: 0.9em;
}

.helpModal {
    max-width: 100%;
    background-color: white;
    margin-inline: 20px;
    margin-top: 20px;
    outline: none;
    border: 1px solid black;

    @include sm {
       margin-inline: 10%;
    }

    @include md {
        margin-inline: 15%;
    }

    @include lg {
        margin-inline: 20%;
    }
}