.fieldWrapper {

    .button {
        background-color: #000000;
        color: #ffffff;
        border: 0;
        border-radius: 5px;
        display: block;
        width: 100%;
        font-size: 1.2em;
        font-weight: 500;
        padding: 20px 0;
        cursor: pointer;

        &:hover {
            opacity: 0.69;
        }
    }

    .disabled {
        opacity: 0.69;
        pointer-events: none;
    }

}