@import 'breakpoints';

.fieldWrapper {
    .title {
        display: block;
        font-size: 0.9em;
        margin-bottom: 4px;
        color: #444444;
    }
    
    .field {
        padding: 10px 5px;
        width: 100%;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid #dddddd;
        color: black;
        background-color: white;
        height: 40px;

        @supports (-webkit-overflow-scrolling: touch) {
            font-size: 16px;
         }
         
        &:focus {
            outline: 0;
        }

        &.errorBorder {
            border-color: red;
        }
    }

    .error {
        color: red;
        font-style: italic;
        font-size: 0.8em;
    }
}