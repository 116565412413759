.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    p {
        font-size: 1.2em;
    }
}