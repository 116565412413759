@import 'breakpoints';

.addressForm {
    display: grid;
    grid-gap: 10px;

    @include sm {
        grid-template-columns: 1fr 1fr;
        .fullWidth {
            grid-column: 1 / 3;   
        }
    }
}