@import 'breakpoints';

.cartWrapper {
    padding: 0;
    margin: 0;
    order: 1;
    // position: fixed;
    width: 100%;
    top: 0px;

    background-color: #f7f8f9;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;

    @include sm {
        padding: 0 50px;
    }
    
    @include md {
        padding: 0 100px;
    }

    @include lg {
        padding: 0 0 0 50px;
        order: 2;
        background-color: transparent;
        position: relative;
        border-top: 0;
        border-bottom: 0;
    }

    .cart {
        background-color: #f7f8f9;
        padding: 15px;
        display: none;

        &.opened {
            display: block;
        }

        @include lg {
            display: block;
            border-bottom: 0;
            border-radius: 10px;
            margin-right: 15px;
            position: -webkit-sticky;
            position: sticky;
            top: 15px;
        }

        table {
            width: 100%;
            font-size: 1em;

            td {
                vertical-align: top;
                padding-bottom: 10px;
            }

            .lineSum {
                text-align: right;
                font-weight: 500;
                min-width: 150px;
            }
        }

        .totals {
            .totalsTitle {
                font-size: 1.1em;
                font-weight: 500;
                padding-bottom: 0;

                small {
                    font-weight: normal;
                    font-size: 0.8em;
                }
            }

            .totalsSum {
                vertical-align: middle;
                font-size: 1.5em;
                font-weight: 500;
                text-align: right;
                padding-bottom: 0;
            }
        }

        h3 {
            font-size: 0.9em;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid #cccccc;
        }
    }

    hr {
        height: 1px;
        border: 0;
        background-color: #cccccc;
        margin: 10px 0;
    }

    small {
        font-style: italic;
    }

    .mobileNav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 1em;
        padding: 5px 15px;

        @include sm {
            font-size: 1em;
            padding: 5px 15px;    
        }

        @include lg {
            display: none;
        }

        .cartHeader {
            display: flex;
            align-items: center;
            padding: 10px 0;

            > svg {
                margin-right: 10px;
            }

            > span {
                margin-right: 5px;
            }
        }

        div:last-of-type {
            padding-left: 15px;
            border-left: 1px solid #cccccc;
        }
    }
}

.merchant {
    font-size: 0.8em;
    color: #666666;
}