@import 'breakpoints';

.breadcrumb {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
  
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
  
      justify-content: space-between;
  
      @include sm {
        justify-content: flex-start;
  
        li {
          margin-right: 5px;
        }
      }
  
      li {
        font-size: 0.8em;
        color: #cccccc;
  
        &.active {
          color: #666666;
          font-weight: 600;
        }
      }
    }
  }