@import 'breakpoints';

.summaryItem {
    padding: 15px;
    border: 1px solid #e6e9eb;
    border-radius: 5px;

    h3 {
        font-size: 0.8em;
        text-transform: uppercase;
        margin-top: 0;
        display: flex;
        justify-content: space-between;

        span {
            font-weight: normal;
            text-transform: initial;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    p {
        margin: 0;
    }
}

.legal {
    font-size: 0.9em;
}

.stepBack {
    text-align: center;
    color: #999999;
    cursor: pointer;
}

.shippingRate {
    padding: 10px 0;
    border-bottom: 1px solid #efefef; 
    display: flex;
    justify-content: space-between;

    &:last-child {
        border-bottom: 0;
    }
}

.shippingRateCost {
    font-weight: 500;
}

.molliePaymentMethods {
    border: 1px solid #e6e9eb;
    border-radius: 5px;
}

.molliePaymentMethod {
    padding: 15px;
    border-bottom: 1px solid #e6e9eb;

    &:last-child {
        border-bottom: 0;
    }

    display: grid;
    grid-template-columns: 30px 40px 1fr;
    align-items: center;
}

.molliePaymentMethodIcon {
    img {
        width: 30px;
        height: auto;
        display: block;
    }
}

.molliePaymentMethodTitle {

}

.molliePaymentMethodRadio {
    input {
        padding: 0;
        margin: 0;
    }
}

.molliePaymentMethodActive {
    background-color: #f7f8f9;
}

.paymentModal {
    max-width: 100%;
    background-color: white;
    margin-inline: 20px;
    margin-top: 20px;
    outline: none;
    border: 1px solid black;
    height: 80vh;

    @include sm {
       margin-inline: 10%;
    }

    @include md {
        margin-inline: 15%;
    }

    @include lg {
        margin-inline: 20%;
    }
}

.link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}