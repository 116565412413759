.wrapper {
    position: relative;
    min-height: 100px;
    padding: 15px;

    h2 {
        margin-top: 0;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 5px;
        }
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}