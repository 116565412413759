@import 'breakpoints';

.fieldWrapper {

    .label {
        font-size: 1.1em;
        display: flex;
        align-items: flex-start;

        @include sm {
            font-size: 1em;
        }

        .checkbox {
            margin-right: 10px;
        }
    }

}